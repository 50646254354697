import * as React from "react"
import { Link } from "gatsby"
import Layout from "@/components/Layout"
import Seo from "@/components/seo"
import OurProcess from "@/components/OurProcess"
import PageIntro from "@/components/PageIntro"
import PageHeading from "@/components/PageHeading"
import ISection from "@/components/ISection"
import Icons, { IArrowRight } from "@/components/Icons"
import { useService } from "@/hooks/use-services"
import IParagraph from "@/components/IParagraph"
import IHeading from "@/components/IHeading"
import CallToActionMini from "@/components/CallToActionMini"
import './styles.css'

const ServicePrimeSection = () => {

  const { allPrimeServices } = useService()

  return (
    <section className="w-full body-font">
      <div className="container flex flex-wrap mx-auto">
        <div className="flex flex-wrap -m-4" data-aos="fade-up">
          {allPrimeServices.map((service: any, index: number) => {
            const { frontmatter, slug, excerpt } = service
            const { title, iconName } = frontmatter
            const icon = Icons[iconName]
            return (
              <>
                <Link to={`/` + slug} className="relative p-4 group lg:w-1/2 md:w-full" data-index={index}>
                  <div className="flex flex-col content-center h-full p-4 duration-200 border-2 border-gray-200 border-opacity-50 md:p-8 rounded-0 sm:flex-row hover:shadow-xl hover:-translate-y-6">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 mx-auto mb-4 border-4 rounded-full shadow-lg sm:mr-8 sm:mb-0 bg-primary-700 text-primary-200 border-primary-100">
                      {icon &&
                        React.createElement(icon, {
                          className: "w-10 h-10",
                        })}
                    </div>
                    <div className="flex-grow">
                      <IHeading size="three" align="text-center md:text-left">{title}</IHeading>
                      <IParagraph>{excerpt}</IParagraph>
                      <Link
                        className="inline-flex items-center mt-3 text-secondary-600 dark:text-secondary-300"
                        to={`/` + slug}
                      >
                        Learn More
                        <IArrowRight className="w-4 h-4 ml-2 dark:text-secondary-300" />
                      </Link>
                    </div>
                  </div>
                </Link>
              </>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const ServiceList = () => {

  const { allServices } = useService()

  return (
    <ISection
      title="Our Services"
      description="At Easesol, we help you with your cloud transformation by implementing cloud solutions that best suit your needs. Our aim is to help businesses set up and manage their cloud without the complexity and cost of hiring their own internal IT engineer staff."
    >
      <div className="grid grid-cols-1 gap-16 pt-5 pb-10 xl:grid-cols-2">
        {allServices.map((service: any, index: number) => {
          const { frontmatter, slug, excerpt } = service
          const { title, iconName, color } = frontmatter
          const icon = Icons[iconName]
          return (
            <div className={`group flex flex-col-reverse md:${index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'} gap-x-8 gap-y-0 items-center`} data-aos="fade-up">
              <div className="flex-grow mt-3 text-center md:mt-6 sm:text-left sm:mt-0">
                <IHeading size="three" align={`${index % 2 === 1 ? 'text-center md:text-left' : 'text-center md:text-right'}`}>{title}</IHeading>
                <IParagraph align={`${index % 2 === 1 ? 'text-center md:text-left' : 'text-center md:text-right'}`}>{excerpt}</IParagraph>
                <div className={`${index % 2 === 1 ? 'text-center md:text-left' : 'text-center md:text-right'}`}>
                  <Link
                    className={`inline-flex items-center mt-3 text-secondary-500 dark:text-secondary-300`}
                    to={`/` + slug}
                  >
                    Learn More
                    <IArrowRight className="w-4 h-4 ml-2 duration-200 group-hover:ml-3 group-hover:duration-200" />
                  </Link>
                </div>
              </div>
              <div className={`inline-flex items-center justify-center flex-shrink-0 w-20 md:w-32 h-20 md:h-32 rounded-2xl bg-${color}-500 text-primary-100`}>
                {
                  icon && React.createElement(icon, { className: "w-10 h-10 sm:w-16 sm:h-16 duration-200 group-hover:scale-125", })
                }
              </div>
            </div>
          )
        })}
      </div>
    </ISection>
  )
}

const ServiceIndexPage = () => {

  return (
    <Layout>
      <Seo title="Cloud Computing Services | Boost Your Business with Expert Support" description="Take your business to new heights with our cloud computing services. Our team of experts can help you choose the right solutions, design a cloud strategy, and provide ongoing support. Let us help you unlock the full potential of the cloud. Contact us today to learn more." />
      <div data-aos="fade-up">
        <PageHeading
          title="What we do"
          description="Solving real-world problems through digital innovation"
        ></PageHeading>
      </div>
      <div data-aos="fade-up">
        <PageIntro>
          Whether you are an IT expert in need of a partner, a corporate
          stakeholder looking to innovate, or an entrepreneur with the next big
          idea, you have come to the right place.
        </PageIntro>
      </div>
      <ServicePrimeSection></ServicePrimeSection>
      <ServiceList></ServiceList>
      <OurProcess></OurProcess>
      <CallToActionMini
        redirectTo="/our-work"
        title="Work"
        description="Explore our cloud computing work and see how we can help your business succeed. Contact us today to learn more."
      ></CallToActionMini>
    </Layout>
  )
}

export default ServiceIndexPage
